<template>
    <v-form ref="timeRegRow" v-model="valid" @submit.prevent="saveTime">
        <div class="d-flex flex-wrap align-center gap-3">
            <v-select
                :items="timeTypes(workOrderId)"
                v-model="localTimeRegistrationType"
                item-text="name"
                item-value="id"
                :rules="[v => v !== null]"
                label="Soort"
                dense
                outlined
                hide-details
            />
        </div>
        <div class="d-flex flex-wrap align-center gap-3">
            <v-select
                :disabled="localTimeReg.workOrder"
                :items="project.formattedProjects"
                item-text="name"
                item-value="value"
                v-model="localTimeReg.project"
                label="Project"
                dense
                :rules="[v => v !== null]"
                outlined
                :readonly="multiUser"
                hide-details
                clearable
            />
            <p>of</p>
            <v-select
                :disabled="!isProjectBased || localTimeReg.project"
                :items="workOrder.projectWorkOrders"
                item-text="name"
                item-value="value"
                v-model="localTimeReg.workOrder"
                :rules="[v => v !== null && isProjectBased]"
                label="Klant/Werkbon"
                dense
                outlined
                :readonly="multiUser"
                hide-details
                clearable
            />
        </div>
        <div class="d-flex flex-wrap align-center gap-3">
            <v-menu
                v-model="menuDatePicker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="localTimeReg.date"
                        label="Datum"
                        readonly
                        outlined
                        dense
                        hide-details
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                </template>
                <v-date-picker
                    v-model="localTimeReg.date"
                    @input="menuDatePicker = false"
                    header-color="#222021"
                    color="#ea5b22"
                    first-day-of-week="1"
                />
            </v-menu>
            <v-text-field
                label="Werkuren"
                type="time"
                step="900"
                min="00:15"
                v-model="localTimeReg.minutesWorkedFormatted"
                dense
                outlined
                hide-details
            />
        </div>
        <div class="d-flex align-center">
            <v-text-field
                label="Omschrijving"
                type="text"
                v-model="localTimeReg.description"
                :rules="[v => v !== null && v.length > 1]"
                dense
                outlined
                hide-details
            />
        </div>
        <div v-if="multiUser">
            <v-select
                label="Werknemers"
                v-model="localTimeReg.employees"
                :items="timeRegistration.users"
                item-text="name"
                item-value="id"
                multiple
                outlined
                dense
                hide-details
            />
        </div>
        <div class="d-flex" :class="{ 'mt-2' : isModal }">
            <v-btn text @click="closeModal" v-if="isModal">
                Sluiten
            </v-btn>
            <v-btn color="green" class="ml-auto white--text" type="submit" :disabled="!formIsValid">
                Opslaan <v-icon size="16" color="white" class="ml-2">fal fa-save</v-icon>
            </v-btn>
        </div>
    </v-form>
</template>

<script>
import {mapGetters, mapState} from 'vuex'

export default {
    name: "TimeRegistrationRow",
    props: {
        timeReg: {type: Object, default: null, required: false},
        activeDate: {type: String, default: null, required: false},
        activeUser: {type: Number, default: null, required: false},
        multiUser: {type: Boolean, default: false, required: false},
        workOrderId: {type: Number, default: null, required: false},
        isModal: { type: Boolean, default: null, required: false }
    },
    data() {
        return {
            valid: false,
            menuDatePicker: false,
            localTimeReg: {
                id: '_' + new Date().getTime(),
                user: this.$store.state.auth.user.id,
                date: null,
                workOrder: null,
                project: null,
                description: '',
                timeRegistrationType: null,
                timeStarted: '08:00',
                minutesWorked: 0,
                minutesWorkedFormatted: '00:00',
                confirmed: null,
                employees: [],
            },
        }
    },
    computed: {
        isProjectBased() {
            return this.$store.getters.timeIsProjectBased(this.localTimeReg.timeRegistrationType)
        },
        formIsValid() {
            const type = this.localTimeReg.timeRegistrationType
            const projectBased = this.isProjectBased
            const wo = this.localTimeReg.workOrder
            const project = this.localTimeReg.project
            const minutesWorked = this.localTimeReg.minutesWorkedFormatted
            const employees = this.localTimeReg.employees
            const description = this.localTimeReg.description

            if ((!type || '00:00' === minutesWorked) || (projectBased && !wo && !project) || description.length === 0) {
                return false
            }

            return !(this.multiUser && employees.length === 0);
        },
        localTimeRegistrationType: {
            get () {
                return this.localTimeReg.timeRegistrationType
            },
            set(value) {
                this.localTimeReg.timeRegistrationType = value
                if (!this.timeIsProjectBased(value)) this.localTimeReg.workOrder = null
            }
        },
        ...mapState(['timeRegistration', 'workOrder', 'project']),
        ...mapGetters({
            timeTypes: 'getTimeTypes',
            timeIsProjectBased: 'timeIsProjectBased'
        }),
    },
    methods: {
        resetTimeReg() {
            this.localTimeReg.id = '_'+new Date().getTime()
            this.localTimeReg.description = ''
            this.localTimeReg.timeRegistrationType = null
            this.localTimeReg.timeStarted = '00:00'
            this.localTimeReg.minutesWorked = 0
            this.localTimeReg.minutesWorkedFormatted = '00:00'
            this.localTimeReg.confirmed = null

            if (!this.workOrderId) this.localTimeReg.workOrder = null
            if (this.multiUser) this.localTimeReg.employees = [this.$store.state.auth.user.id]
        },
        saveTime() {
            if (this.formIsValid) {
                this.$store.dispatch('saveToDatabase', this.localTimeReg)
                    .then(() => {
                        if (this.workOrderId && !this.timeReg) {
                            this.$store.dispatch('getWorkOrder', {'workOrderId': this.workOrderId})
                        } else {
                            const payload = {'date': this.localTimeReg.date, 'user': this.localTimeReg.user}
                            this.$store.dispatch('getTimeRegistrationsForWeekOfUser', payload)
                        }

                        this.resetTimeReg()
                        this.$refs.timeRegRow.resetValidation()

                        if (this.isModal) {
                            this.closeModal()
                            this.$emit('reloadWorkOrder')
                        }
                    })
            } else {
                this.$store.dispatch('showSnackbar', {text: 'Niet alle velden zijn ingevuld', color: 'red darken-2'})
            }
        },
        closeModal() {
            this.$emit('closeModal')
        }
    },
    mounted() {
        this.$store.dispatch('getTimeRegistrationTypes')
        this.$store.dispatch('getUsersWithTimeRegistrations')
        this.$store.dispatch('getProjectWorkOrder', {userId: this.activeUser})
        this.$store.dispatch('getProjects')

        if (null !== this.timeReg) this.localTimeReg = JSON.parse(JSON.stringify(this.timeReg))

        if (null !== this.activeDate) this.localTimeReg.date = this.activeDate
        if (null !== this.activeUser) this.localTimeReg.user = this.activeUser

        if (this.multiUser) this.localTimeReg.employees = [this.$store.state.auth.user.id]

        if (null !== this.workOrderId) {
            this.localTimeReg.workOrder = this.workOrderId
            this.localTimeReg.employees = [this.activeUser]
        } else {
            this.localTimeReg.workOrder = null
            this.employees = null
        }
    },
    watch: {
        timeReg(newValue) {
            this.localTimeReg = JSON.parse(JSON.stringify(newValue))
        },
        activeDate(newValue) {
            this.localTimeReg.date = newValue
        },
        activeUser(newValue) {
            this.$store.dispatch('getProjectWorkOrder', {userId: newValue})
            this.localTimeReg.user = newValue
        },
    }
}
</script>

<style scoped>
.odd-row {
    background: #efefef;
}

.unconfirmed {
    border-left: 3px solid blue;
}

.gap-3 {
    gap: 12px;
}

.v-input {
    margin-bottom: 10px
}
</style>

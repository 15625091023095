<template>
    <div>
        <v-data-table
            dense
            :headers="headers"
            :items="timeRegistrations"
            :items-per-page="10"
            class="elevation-0"
            :item-class="itemClass"
        >
            <template v-slot:item.date="{ item }">
                {{ item.date|luxon({output: {format: "dd-MM-yyyy" } }) }}
            </template>
            <template v-slot:item.timeRegistrationType="{ item }">
                {{ $store.getters.getTimeTypeNameById(item.timeRegistrationType) }}
            </template>
            <template v-slot:item.workOrder="{ item }">
                {{ item.projectWorkOrder }}
            </template>
            <template v-slot:item.minutesWorked="{ item }">
                {{ timeWorked(item.minutesWorked) }}
            </template>
            <template v-slot:item.user="{ item }">
                {{ $store.getters.getUsernameById(item.user) }}
            </template>
            <template v-slot:item.actions="{ item }">
                <v-flex v-if="(!item.confirmed && !item.exportGildeAt && timesEditable && item.user === $store.state.auth.user.id) || $store.getters.userIsAdmin">
                    <v-btn small text color="secondary" @click="editRow(item)">
                        <v-icon size="16">fas fa-pencil</v-icon>
                    </v-btn>
                    <v-btn small text color="red" @click="deleteRow(item)" order="2">
                        <v-icon size="16">fas fa-trash-alt</v-icon>
                    </v-btn>
                </v-flex>
            </template>
        </v-data-table>

        <v-dialog
            v-model="dialogEdit"
            width="500"
        >
            <v-card>
                <v-card-title>
                    Tijdsregistratie aanpassen
                </v-card-title>
                <div>
                    <v-card-text>
                        <TimeRegistrationRow
                            v-if="editedItem"
                            :time-reg="editedItem"
                            :work-order-id="editedItem.workOrder"
                            :active-user="editedItem.user"
                            :isModal="true"
                            @closeModal="dialogEdit = false"
                            @reloadWorkOrder="$emit('reloadTimes')"
                        />
                    </v-card-text>
                </div>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="text-center d-block">Bevestigen</v-card-title>
                <v-card-text>
                    <div>
                        <v-card-subtitle class="text-center" v-if="editedItem">
                            Weet je zeker dat je <strong>{{ editedItem.description }}</strong> wilt verwijderen?
                        </v-card-subtitle>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="closeDelete">Annuleren</v-btn>
                    <v-btn color="green darken-1" text @click="deleteItemConfirm">Ja</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import TimeRegistrationRow from "@/components/timeRegistration/TimeRegistrationRow.vue";

export default {
    name: "TimeRegistrationsDataTable",
    components: {TimeRegistrationRow},
    props: {
        timeRegistrations: {type: Array, required: true},
        compactView: {type: Boolean, default: false, required: false},
        timesEditable: {type: Boolean, default: true, required: false},
    },
    data() {
        return {
            dialogEdit: false,
            dialogDelete: false,
            editedItem: null,
        }
    },
    computed: {
        headers() {
            let headers = [
                { text: 'Datum en tijd', value: 'date'},
                { text: 'Omschrijving', value: 'description' },
                { text: 'Type', value: 'timeRegistrationType' },
                { text: 'Uren', value: 'minutesWorked' },
            ]

            if (this.compactView) {
                headers.push({ text: 'Monteur', value: 'user' })
            } else {
                headers.push({ text: 'Werkbon', value: 'workOrder' })
            }

            if (this.timesEditable) {
                headers.push({ text: 'Acties', value: 'actions', width: '150px'})
            }

            return headers
        }
    },
    methods: {
        reloadTimes() {
            this.$emit('reloadTimes')
            this.dialog = false
        },
        timeWorked(input) {
            let hours = Math.floor(input / 60);
            let minutes = input % 60;
            if (minutes < 10) minutes = '0' + minutes;

            return `${hours}:${minutes}`;
        },
        itemClass: function (item) {
            return item.confirmed ? 'confirmed' : 'awaiting'
        },
        closeDelete () {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = false
            })
        },
        editRow(item) {
            this.dialogEdit = true
            this.editedItem = item
        },
        deleteRow(item) {
            this.dialogDelete = true
            this.editedItem = item
        },
        deleteItemConfirm () {
            this.dialogDelete = false
            this.$store.dispatch('deleteTime', {timeId: this.editedItem.id})
                .then(() => {
                    this.$store.dispatch('showSnackbar', {text: 'Tijd verwijderd'})
                    this.reloadTimes()
                    this.editedItem = false
                })
                .catch(() => {
                    this.$store.dispatch('showSnackbar', {text: 'Fout bij verwijderen', color: 'red darken-2'})
                    this.editedItem = false
                })
        },
    },
}
</script>

<style scoped>

</style>

import { render, staticRenderFns } from "./TimeRegistrationsDataTable.vue?vue&type=template&id=babc703c&scoped=true&"
import script from "./TimeRegistrationsDataTable.vue?vue&type=script&lang=js&"
export * from "./TimeRegistrationsDataTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "babc703c",
  null
  
)

export default component.exports
<template>
    <v-container>
        <v-row>
            <v-col sm="12" lg="3" order-lg="2">
                <v-date-picker
                    full-width
                    v-model="activeDate"
                    header-color="#222021"
                    first-day-of-week="1"
                    locale-first-day-of-year="1"
                    show-week
                    color="#ea5b22"
                    elevation="2"
                    class="mb-2"
                ></v-date-picker>

                <v-list dense v-if="$store.getters.userIsAdmin">
                    <v-card>
                        <v-card-title>
                            Gebruikers
                        </v-card-title>
                        <v-card-text>
                            <v-list-item-group
                                v-model="selectedUser"
                                color="primary"
                            >
                                <v-list-item
                                    v-for="(user) in timeRegistration.users"
                                    :key="user.id"
                                    :value="user.id"
                                    @click="selectedUser = user.id"
                                    :disabled="user.id === selectedUser"
                                >
                                    <v-list-item-icon>
                                        <v-icon size="16">far fa-user</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ user.name }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-card-text>
                    </v-card>
                </v-list>
            </v-col>
            <v-col cols="12" sm="12" lg="9">
                <v-card>
                    <v-card-title>
                        Tijdsregistratie - {{ activeDate|luxon({output: {format: 'DATE_FULL'} }) }}
                    </v-card-title>
                    <v-card-text>

                        <v-skeleton-loader
                            v-if="$store.state.timeRegistration.loading"
                            type="article, article"
                        />
                        <div v-else>
                            <v-card-subtitle class="pa-0 mb-4">
                                Nieuwe invoer:
                            </v-card-subtitle>
                            <TimeRegistrationRow
                                :active-date="activeDate"
                                :active-user="selectedUser"
                                class="mb-4"
                            />
                        </div>

                    </v-card-text>
                </v-card>

                <v-card class="mt-5">
                    <v-card-title>Weeksamenvatting - week {{ activeDate|luxon({ output: { format:'W'} }) }}</v-card-title>
                    <v-card-text>
                        <v-skeleton-loader
                            v-if="$store.state.timeRegistration.loading"
                            type="article"
                        />
                        <div v-else>
                            <OverviewWeek class="mb-5" />

                            <TimeRegistrationsDataTable
                                :timeRegistrations="timeRegistration.timeRegistrations"
                                @reloadTimes="loadTimes"
                            />
                        </div>
                        <div class="d-flex justify-end">
                            <v-btn
                                v-if="showSubmitTimeRegistrationsBtn"
                                @click="submitTimeRegs"
                                color="secondary"
                                class="mt-5"
                            >
                                Uren Inleveren
                            </v-btn>
                        </div>

                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import TimeRegistrationRow from "@/components/timeRegistration/TimeRegistrationRow.vue";
import {mapState, mapGetters} from "vuex";
import OverviewWeek from "@/components/timeRegistration/OverviewWeek.vue";
import TimeRegistrationsDataTable from "../components/timeRegistration/TimeRegistrationsDataTable.vue";

export default {
    name: "TimeRegistrationListView",
    components: {TimeRegistrationsDataTable, OverviewWeek, TimeRegistrationRow},
    computed: {
        ...mapState(['timeRegistration']),
        ...mapGetters(['getTimesOfDay']),
        showSubmitTimeRegistrationsBtn() {
            let count = this.timeRegistration.timeRegistrations.filter(time => !time.confirmed).length
            return count > 0 && this.$store.state.auth.user.id === this.selectedUser
        },
        activeDate: {
            get() {
                return (this.$route.query.date) ? this.$route.query.date : new Date().toISOString().split('T')[0]
            },
            set(val) {
                if (val !== this.activeDate) {
                    this.$router.push({name: 'TimeRegistrationView', query: {date: val, user: this.selectedUser}})
                }
            }
        },
        selectedUser: {
            get() {
                if (this.$route.query.user) {
                    return parseInt(this.$route.query.user)
                }
                else if (this.$store.state.auth.user) {
                    return this.$store.state.auth.user.id
                }
                else {
                    return null
                }
            },
            set(val) {
                if (val !== this.selectedUser) {
                    this.$router.push({name: 'TimeRegistrationView', query: {date: this.activeDate, user: val}})
                }
            }
        }
    },
    methods: {
        loadTimes() {
            this.$store.dispatch('getTimeRegistrationsForWeekOfUser', {'date': this.activeDate, 'user': this.selectedUser})
        },
        submitTimeRegs() {
            this.$store.dispatch('submitTimeRegs', {date: this.activeDate, user: this.selectedUser})
        },
    },
    mounted() {
        this.loadTimes()
    },
    watch: {
        $route(to, from) {
            if (to !== from) {
                this.loadTimes()
            }
        }
    }
}
</script>

<style scoped>

</style>

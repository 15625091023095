<template>
    <v-row>
        <v-col
            v-for="day in week"
            :key="day.day"
            :class="[day.minutesWorked >= day.workDayMinutes ? 'green--text' : 'red--text']"
        >
            {{day.day}}: <strong class="d-block">{{ timeWorked(day.minutesWorked) }}</strong>
        </v-col>
    </v-row>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "OverviewWeek",
    computed: {
        ...mapState(['timeRegistration']),
        week() {
            // reset
            let week = [
                {day:'Maandag', minutesWorked: 0, workDayMinutes: 480},
                {day:'Dinsdag', minutesWorked: 0, workDayMinutes: 480},
                {day:'Woensdag', minutesWorked: 0, workDayMinutes: 480},
                {day:'Donderdag', minutesWorked: 0, workDayMinutes: 480},
                {day:'Vrijdag', minutesWorked: 0, workDayMinutes: 480},
                {day:'Zaterdag', minutesWorked: 0, workDayMinutes: 0},
                {day:'Zondag', minutesWorked: 0, workDayMinutes: 0},
            ]

            this.timeRegistration.timeRegistrations.forEach(time => {
                let id = this.$luxon(time.date, 'c') - 1
                week[id].minutesWorked += time.minutesWorked
            })

            return week
        }
    },
    methods: {
        timeWorked(input) {
            let hours = Math.floor(input / 60);
            let minutes = input % 60;
            if (minutes < 10) minutes = '0' + minutes;

            return `${hours}:${minutes}`;
        }
    },
}
</script>

<style scoped>

</style>
